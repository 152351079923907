import React, { useState, useContext, useEffect } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonText, IonItem, IonCheckbox, IonButton, IonIcon } from "@ionic/react"
import WarehouseCard from "../components/WarehouseCard"
import { checkmarkOutline, closeOutline } from "ionicons/icons"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { chevronDownOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import useToast from "../hooks/useToast"
import { UserContext } from "../components/UserContext"

const Accessories = (props) => {
	const match = props.match
	const { t } = useTranslation("link_app")
	const [user] = useContext(UserContext)
	const toast = useToast()

	// Databases
	const databases = useContext(DatabaseContext)

	// Edited state
	const [isEdited, setIsEdited] = useState(false)

	// Updated state
	const [isUpdated, setIsUpdated] = useState(false)

	// Fetch ticket
	const [ticket, setTicket] = useState({})
	useEffect(() => {
		databases.locals.tickets
			.get(match.params.id)
			.then((result) => {
				setTicket(result)
				setIsUpdated(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.tickets, match, props.isLoading, isUpdated])

	// Update changes to database
	useEffect(() => {
		if (isEdited) {
			databases.locals.tickets
				.put(ticket)
				.then((result) => {
					console.log(`${t("toast.ticket")} #${ticket._id} ${t("toast.updated")}`)
					toast(`${t("toast.ticket")} #${ticket._id} ${t("toast.updated")}`)
					setIsEdited(false)
					setIsUpdated(true)
				})
				.catch((err) => {
					console.log(`${t("toast.error_updating_ticket")} #${ticket._id}:`, err)
					toast(`${t("toast.error_updating_ticket")} #${ticket._id}`)
				})
		}
	}, [isEdited, ticket, databases.locals.tickets, match, t, toast])

	// Fetch warehouses
	const [warehouse, setWarehouse] = useState({})
	useEffect(() => {
		if (ticket.warehouse?.id) {
			databases.locals.warehouses
				.get(ticket.warehouse.id)
				.then((result) => {
					setWarehouse(result)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [databases.locals.warehouses, ticket.warehouse])

	// Function that adds accessories timestamp to ticket
	const handleAccessoriesComplete = () => {
		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			ticket.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								ticket.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticketCopy = {
			...ticket,
			time_when_accessories: ticket.time_when_accessories === "" ? timestamp : "",
			log: ticket.log
				? [...ticket.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: ticket.time_when_accessories === "" ? "at_warehouse" : "cancel_at_warehouse" }]
				: [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: ticket.time_when_accessories === "" ? "at_warehouse" : "cancel_at_warehouse" }],
		}
		setTicket(ticketCopy)
		setIsEdited(true)
	}

	useEffect(() => {
		document.title = `${t("tickets.ticket")} #${ticket._id} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [ticket, t, match])

	return (
		<IonPage>
			<Header title={t("tickets.accessories")} backButton />
			<IonContent>
				{ticket._id ? (
					<>
						<IonList>
							<IonItem lines="full">
								<div className="inner-wrap">
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
											<IonText>
												<h4 className="ion-margin-none">{t("tickets.equipment")}</h4>
											</IonText>
										</AccordionSummary>
										<AccordionDetails>
											{ticket.accessories.length > 0 &&
												ticket.accessories.map((accessory, i) => {
													return (
														<IonItem key={i} className="ion-margin-bottom" lines="none">
															<div className="checkbox-wrap">
																<IonCheckbox value={accessory.id} checked={false} />
																<IonText>
																	<p className="ion-margin-none ion-margin-left">{accessory.name}</p>
																</IonText>
															</div>
														</IonItem>
													)
												})}
										</AccordionDetails>
									</Accordion>
								</div>
							</IonItem>
							{ticket.warehouse?.id && (
								<IonItem lines="full">
									<div className="inner-wrap">
										<Accordion defaultExpanded>
											<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
												<IonText>
													<h4 className="ion-margin-none">{t("tickets.warehouses")}</h4>
												</IonText>
											</AccordionSummary>
											<AccordionDetails>{warehouse._id && <WarehouseCard warehouse={warehouse} />}</AccordionDetails>
										</Accordion>
									</div>
								</IonItem>
							)}
						</IonList>
						<div className="ion-padding">
							{ticket.time_when_accessories === "" ? (
								<IonButton color="success" expand="full" onClick={() => handleAccessoriesComplete()}>
									<IonIcon className="ion-margin-right-half" slot="start" icon={checkmarkOutline} />
									{t("tickets.mark_ready")}
								</IonButton>
							) : (
								<IonButton color="danger" expand="full" onClick={() => handleAccessoriesComplete()}>
									<IonIcon className="ion-margin-right-half" slot="start" icon={closeOutline} />
									{t("tickets.cancel")}
								</IonButton>
							)}
						</div>
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Accessories
