import React, { useState, useEffect, useContext } from "react"
import Header from "../components/Header"
import { IonList, IonItem, IonLabel, IonPage, IonContent, IonSearchbar } from "@ionic/react"
import { UserContext } from "../components/UserContext"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import { useTranslation } from "react-i18next"

const Contacts = (props) => {
	const { t } = useTranslation("link_app")
	const match = props.match

	// Databases
	const databases = useContext(DatabaseContext)

	// State for search
	const [searchText, setSearchText] = useState("")

	// Get current user from user context
	const [user] = useContext(UserContext)

	// Fetching state
	const [isFetching, setIsFetching] = useState(true)

	// Fetch contacts
	const [contacts, setContacts] = useState([])
	useEffect(() => {
		databases.locals.contacts
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design") && row.doc.email !== user.name
				})
				setContacts(docs)
				setIsFetching(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.contacts, match, props.isLoading, user])

	useEffect(() => {
		document.title = `${t("app.contacts")} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [t, match])

	return (
		<IonPage>
			<Header title={t("app.contacts")} />
			<IonContent>
				{!isFetching ? (
					<>
						<IonSearchbar
							className="ion-padding-top ion-padding-left ion-padding-right"
							value={searchText}
							onIonChange={(e) => {
								e.preventDefault()
								setSearchText(e.detail.value)
							}}
							placeholder={t("contacts.search")}
						></IonSearchbar>
						<IonList>
							{contacts.length > 0 &&
								contacts
									.filter((item) => {
										if (searchText !== "") {
											return item.doc.name.toLowerCase().includes(searchText.toLowerCase()) && item.doc?.email !== user.email
										} else {
											return item
										}
									})
									.map((item, i) => {
										const firstName = item.doc?.name?.split(" ")[0]
										const lastName = item.doc?.name?.split(" ")[1]
										return (
											<IonItem key={i} routerLink={`/contacts/${item.id}`} lines="full">
												<div className="icon-wrap primary" slot="start">
													{firstName && firstName.charAt(0)}
													{lastName && lastName.charAt(0)}
												</div>
												<IonLabel className="ion-padding-top ion-padding-bottom">
													{item.doc.name}
													<br />
													<span className="contact-title">{item.doc.title}</span>
												</IonLabel>
											</IonItem>
										)
									})}
						</IonList>
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Contacts
