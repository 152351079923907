import React, { useState, useContext, useEffect } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonText, IonItem, IonIcon, IonLabel, IonCheckbox, IonButton, IonSegment, IonSegmentButton } from "@ionic/react"
import { readerOutline, chevronForwardOutline, checkmarkOutline, closeOutline, documentOutline, cloudDownloadOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import useToast from "../hooks/useToast"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import _ from "lodash"
import { UserContext } from "../components/UserContext"

const Maintenance = (props) => {
	// Misc vars
	const { match, isLoading } = props
	const { t } = useTranslation("link_app")
	const [user] = useContext(UserContext)
	const [segment, setSegment] = useState("maintenance")
	const [loadingAttachment, setLoadingAttachment] = useState(false)
	const toast = useToast()

	// Databases
	const databases = useContext(DatabaseContext)

	// Edited state
	const [isEdited, setIsEdited] = useState(false)

	// Updated state
	const [isUpdated, setIsUpdated] = useState(true)

	// Fetch ticket
	const [doc, setDoc] = useState({})
	useEffect(() => {
		databases.locals.tickets
			.get(match.params.id)
			.then((result) => {
				setDoc(result)
				setIsUpdated(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.tickets, match, isLoading, isUpdated])

	useEffect(() => {
		document.title = `${t("tickets.ticket")} #${doc._id} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [doc, t, match])

	// Update changes to database
	useEffect(() => {
		if (isEdited) {
			databases.locals.tickets
				.put(doc)
				.then((result) => {
					console.log(result)
					console.log(`${t("toast.ticket")} #${doc._id} ${t("toast.updated")}`)
					toast(`${t("toast.ticket")} #${doc._id} ${t("toast.updated")}`)
					setIsEdited(false)
					setIsUpdated(true)
				})
				.catch((err) => {
					console.log(`${t("toast.error_updating_ticket")} #${doc._id}:`, err)
					toast(`${t("toast.error_updating_ticket")} #${doc._id}`)
				})
		}
	}, [isEdited, doc, databases.locals.tickets, match, t, toast])

	// Fetch all accessories with accessory ids
	const [accessories, setAccessories] = useState([])
	useEffect(() => {
		if (doc._id && !_.isEmpty(doc.accessories)) {
			const accessoryIds = doc.accessories.map((accessory) => {
				return accessory.id
			})
			databases.locals.accessories
				.allDocs({ include_docs: true, keys: accessoryIds })
				.then((result) => {
					const docs = result.rows.filter((row) => {
						return !row?.id?.includes("_design")
					})
					setAccessories(docs)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [doc, databases.locals.accessories, doc.accessories])

	// Fetch fm service
	const [fmService, setFmService] = useState({})
	useEffect(() => {
		if (doc.fm_service?.id) {
			databases.remotes.fm_services
				.get(doc.fm_service.id)
				.then((result) => {
					setFmService(result)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [databases.remotes.fm_services, doc])

	// Fetch all software presets
	const [softwarePresets, setSoftwarePresets] = useState([])
	useEffect(() => {
		databases.remotes.software_presets
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setSoftwarePresets(docs)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.remotes.software_presets])

	// Handle checkbox click
	const handleCheck = (phaseId) => {
		const timestamp = Math.round(new Date() / 1000).toString()
		const phases = [...doc.maintenance_phases]
		const index = phases.findIndex((phase) => phase.id === phaseId)
		phases[index].is_checked = !phases[index].is_checked

		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			maintenance_phases: phases,
			time_when_started: doc.time_when_started === "" ? timestamp : doc.time_when_started,
			time_when_paused: "",
			log: doc.log
				? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: `phase_${phases[index].text}: ${phases[index].is_checked}` }]
				: [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: `phase_${phases[index].text}: ${phases[index].is_checked}` }],
		}
		setDoc(ticket)
		setIsEdited(true)
	}

	// Handle ticket maintenance complete
	const handleMaintenanceComplete = () => {
		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			time_when_completed: doc.time_when_completed === "" ? timestamp : "",
			log: doc.log
				? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: doc.time_when_completed === "" ? "completed" : "cancel_completed" }]
				: [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: doc.time_when_completed === "" ? "completed" : "cancel_completed" }],
		}
		setDoc(ticket)
		setIsEdited(true)
	}

	const filteredPresets = softwarePresets.filter((preset) => {
		const ticket = JSON.stringify({ ...doc.place, ...doc.accessories, ...doc.fm_service })
		const accessoryName = preset.doc?.accessory?.name
		const frequency = preset.doc?.frequency
		const fmServiceName = preset.doc?.fm_service
		const siteId = preset.doc?.place?.site_id

		if (fmServiceName && fmServiceName !== "") {
			return ticket.includes(preset.doc?.fm_service)
		} else if (frequency && frequency !== "" && fmService?.frequency !== "") {
			return fmService.frequency?.includes(frequency)
		} else if (accessoryName && accessoryName !== "") {
			return ticket.includes(preset.doc?.accessory?.name)
		} else if (siteId && siteId !== "") {
			return ticket.includes(preset.doc?.place?.site_id)
		} else if (siteId === "" || !siteId) {
			return preset
		} else return null
	})

	const handleDownload = async (softwarePreset) => {
		setLoadingAttachment(true)
		const attachments = softwarePreset?.doc?._attachments
		if (!_.isEmpty(attachments)) {
			const attachmentName = Object.keys(attachments)[0]
			databases.remotes.software_presets
				.getAttachment(softwarePreset.doc._id, attachmentName)
				.then((response) => {
					if (response) {
						const url = window.URL.createObjectURL(response)
						const link = document.createElement("a")
						link.href = url
						link.setAttribute("download", attachmentName)
						document.body.appendChild(link)
						link.click()
						link.parentNode.removeChild(link)
					}
					setLoadingAttachment(false)
				})
				.catch((err) => {
					console.log(err)
					setLoadingAttachment(false)
				})
		} else {
			setLoadingAttachment(false)
		}
	}

	return (
		<IonPage>
			<Header title={t("header.maintenance")} backButton />
			<IonContent>
				{doc._id && !loadingAttachment ? (
					<>
						<IonSegment
							onIonChange={(e) => {
								setSegment(e.detail.value)
							}}
							value={segment}
							scrollable
						>
							<IonSegmentButton value="maintenance">
								<IonLabel>{t("maintenance.maintenance")}</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="guides">
								<IonLabel>{t("maintenance.guides")}</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="softwarePresets">
								<IonLabel>{t("maintenance.software_presets")}</IonLabel>
							</IonSegmentButton>
						</IonSegment>
						{segment === "maintenance" && (
							<>
								<IonList>
									<div className="ion-border-bottom ion-padding-left ion-padding-right">
										<div className="ticket-inner-wrap">
											<IonText>
												<h4>{t("maintenance.title")}</h4>
											</IonText>
											<IonText>
												<p>{t("maintenance.sub_title")}</p>
											</IonText>
											<IonList>
												{doc.maintenance_phases?.map((item, i) => {
													return (
														<IonItem key={i} className="ion-margin-bottom" lines="none" onClick={() => handleCheck(item.id)}>
															<div className="checkbox-wrap">
																<IonCheckbox value={item.text} checked={item.is_checked} />
																<IonText>
																	<p className="ion-margin-none ion-margin-left">{item.text}</p>
																</IonText>
															</div>
														</IonItem>
													)
												})}
											</IonList>
										</div>
									</div>
								</IonList>
								<div className="ion-padding">
									{doc.time_when_completed === "" ? (
										<IonButton color="success" expand="full" onClick={() => handleMaintenanceComplete()}>
											<IonIcon className="ion-margin-right-half" slot="start" icon={checkmarkOutline} />
											{t("tickets.mark_ready")}
										</IonButton>
									) : (
										<IonButton color="danger" expand="full" onClick={() => handleMaintenanceComplete()}>
											<IonIcon className="ion-margin-right-half" slot="start" icon={closeOutline} />
											{t("tickets.cancel")}
										</IonButton>
									)}
								</div>
							</>
						)}

						{segment === "guides" && (
							<IonList>
								<IonItem lines="full">
									<div className="ticket-inner-wrap">
										<IonText>
											<h4 className="ion-margin-none">{t("maintenance.manuals_title")}</h4>
										</IonText>
										{accessories?.map((accessory, i) => {
											return (
												<div key={i}>
													<IonText>
														<p className="ion-margin-none ion-margin-top">{accessory.doc?.name}</p>
													</IonText>
													<IonList className="ion-margin-top">
														{accessory.doc?.manuals?.map((manual, i) => {
															return (
																<IonItem key={i} lines="none" routerLink={`/accessories/${accessory.doc?._id}/manuals/${manual?.id}`}>
																	<IonIcon icon={readerOutline} color="primary" slot="start" />
																	<IonLabel>{manual.title}</IonLabel>
																	<IonIcon slot="end" icon={chevronForwardOutline} color="primary" />
																</IonItem>
															)
														})}
													</IonList>
												</div>
											)
										})}
									</div>
								</IonItem>
							</IonList>
						)}

						{segment === "softwarePresets" && (
							<IonList>
								<IonItem lines="full">
									<div className="ticket-inner-wrap">
										<IonText>
											<h4 className="ion-margin-none">{t("maintenance.software_presets_title")}</h4>
										</IonText>
										<IonList className="ion-margin-top">
											{filteredPresets?.map((preset, i) => {
												return (
													<IonItem key={i} lines="none" button onClick={() => handleDownload(preset)}>
														<IonIcon icon={documentOutline} color="primary" slot="start" />
														<IonLabel>
															{preset.doc?.name}
															{preset.doc?.version ? ` ${preset.doc?.version}` : ""}
														</IonLabel>
														<IonIcon slot="end" icon={cloudDownloadOutline} size="small" color="primary" />
													</IonItem>
												)
											})}
										</IonList>
									</div>
								</IonItem>
							</IonList>
						)}
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Maintenance
