import React, { useEffect, useState, useContext } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonLabel, IonSegment, IonSegmentButton } from "@ionic/react"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import useToast from "../hooks/useToast"
import { UserContext } from "../components/UserContext"
import PlaceGeneral from "../components/PlaceGeneral"
import PlaceFMServices from "../components/PlaceFMServices"
import PlaceLog from "../components/PlaceLog"
import PlaceSystems from "../components/PlaceSystems"
import PlaceMonitoring from "../components/PlaceMonitoring"
import StudioTransmitters from "../components/StudioTransmitters"
import StudioServices from "../components/StudioServices"

// Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from "mapbox-gl"
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const Place = (props) => {
	// Misc vars
	const match = props.match
	const { t } = useTranslation("link_app")
	const selectionType = props.match?.params?.type
	const toast = useToast()

	// Databases
	const databases = useContext(DatabaseContext)

	// Edited state
	const [isEdited, setIsEdited] = useState(false)

	// Updated state
	const [isUpdated, setIsUpdated] = useState(true)

	// Get current user from user context
	const [user] = useContext(UserContext)

	// Fetch the place
	const [doc, setDoc] = useState({})

	useEffect(() => {
		if (selectionType === "site") {
			databases.locals.places
				.get(match.params.id, { attachments: true })
				.then((result) => {
					setDoc(result)
					setIsUpdated(false)
				})
				.catch((err) => {
					console.log(err)
				})
		} else if (selectionType === "warehouse") {
			databases.locals.warehouses
				.get(match.params.id, { attachments: true })
				.then((result) => {
					setDoc(result)
					setIsUpdated(false)
				})
				.catch((err) => {
					console.log(err)
				})
		} else if (selectionType === "studio") {
			databases.locals.studios
				.get(match.params.id, { attachments: true })
				.then((result) => {
					setDoc(result)
					setIsUpdated(false)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [databases.locals.places, databases.locals.warehouses, databases.locals.studios, match, props.isLoading, isUpdated, selectionType])

	useEffect(() => {
		document.title = `${doc.name} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [doc, match])

	// Update changes to database
	useEffect(() => {
		if (isEdited) {
			databases.locals.places
				.put(doc)
				.then((result) => {
					console.log(result)
					console.log(`${t("toast.place")} "${doc.name}" ${t("toast.updated")}`)
					toast(`${t("toast.place")} "${doc.name}" ${t("toast.updated")}`)
					setIsEdited(false)
					setIsUpdated(true)
				})
				.catch((err) => {
					console.log(`${t("toast.error_updating_place")} "${doc.name}":`, err)
					toast(`${t("toast.error_updating_place")} "${doc.name}"`)
				})
		}
	}, [isEdited, doc, databases.locals.places, match, t, toast])

	// Fetch ticket history
	const [ticketHistory, setTicketHistory] = useState([])
	useEffect(() => {
		databases.locals.ticket_history
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const tickets = result.rows
					.filter((row) => {
						return !row.id.includes("_design")
					})
					.filter((row) => {
						return row.doc.place.site_id === doc.site_id
					})
				setTicketHistory(tickets)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.ticket_history, props.isLoading, doc])

	// Fetch FM services tied to this place
	const [fmServices, setFmServices] = useState([])
	useEffect(() => {
		databases.locals.fm_services
			.allDocs({ include_docs: true, attachments: true, keys: doc.fm_services })
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setFmServices(docs)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.fm_services, doc, match])

	// State for segment tabs
	const [segment, setSegment] = useState("general")

	return (
		<IonPage>
			<Header title={doc.name} backButton />
			<IonContent>
				{doc._id ? (
					<>
						<IonSegment
							onIonChange={(e) => {
								e.preventDefault()
								setSegment(e.detail.value)
							}}
							value={segment}
							scrollable
						>
							{selectionType === "site" && (
								<>
									<IonSegmentButton value="general">
										<IonLabel>{t("places.general")}</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="fm-services">
										<IonLabel>{t("places.fm_services")}</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="systems">
										<IonLabel>{t("places.systems")}</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="log">
										<IonLabel>{t("places.log")}</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="monitoring">
										<IonLabel>{t("places.monitoring")}</IonLabel>
									</IonSegmentButton>
								</>
							)}
							{selectionType === "warehouse" && (
								<>
									<IonSegmentButton value="general">
										<IonLabel>{t("places.general")}</IonLabel>
									</IonSegmentButton>
								</>
							)}
							{selectionType === "studio" && (
								<>
									<IonSegmentButton value="general">
										<IonLabel>{t("places.general")}</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="transmitters">
										<IonLabel>{t("places.transmitters")}</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="services">
										<IonLabel>{t("places.services")}</IonLabel>
									</IonSegmentButton>
								</>
							)}
						</IonSegment>
						{selectionType === "site" && (
							<>
								{segment === "general" && <PlaceGeneral doc={doc} setDoc={setDoc} match={match} isEdited={isEdited} setIsEdited={setIsEdited} user={user} selectionType={selectionType} />}
								{segment === "fm-services" && <PlaceFMServices doc={doc} user={user} fmServices={fmServices} />}
								{segment === "systems" && <PlaceSystems doc={doc} />}
								{segment === "log" && <PlaceLog doc={doc} setDoc={setDoc} ticketHistory={ticketHistory} setIsEdited={setIsEdited} />}
								{segment === "monitoring" && <PlaceMonitoring doc={doc} />}
							</>
						)}
						{selectionType === "warehouse" && <>{segment === "general" && <PlaceGeneral doc={doc} setDoc={setDoc} match={match} isEdited={isEdited} setIsEdited={setIsEdited} user={user} selectionType={selectionType} />}</>}
						{selectionType === "studio" && (
							<>
								{segment === "general" && <PlaceGeneral doc={doc} setDoc={setDoc} match={match} isEdited={isEdited} setIsEdited={setIsEdited} user={user} selectionType={selectionType} />}
								{segment === "transmitters" && <StudioTransmitters doc={doc} />}
								{segment === "services" && <StudioServices doc={doc} />}
							</>
						)}
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Place
