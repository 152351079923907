import React, { useState, useContext, useEffect } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonText } from "@ionic/react"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import Tasks from "../components/Tasks"
import useToast from "../hooks/useToast"

const TicketTasks = (props) => {
	// Misc vars
	const match = props.match
	const { t } = useTranslation("link_app")
	const toast = useToast()

	// Databases
	const databases = useContext(DatabaseContext)

	// Edited state
	const [isEdited, setIsEdited] = useState(false)

	// Updated state
	const [isUpdated, setIsUpdated] = useState(true)

	// Fetch ticket
	const [doc, setDoc] = useState({})
	useEffect(() => {
		databases.locals.tickets
			.get(match.params.id)
			.then((result) => {
				setDoc(result)
				setIsUpdated(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.tickets, match, props.isLoading, isUpdated])

	useEffect(() => {
		document.title = `${t("tickets.ticket")} #${doc._id} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [doc, t, match])

	// Fetch the place
	const [place, setPlace] = useState({})
	useEffect(() => {
		databases.locals.places
			.allDocs({ include_docs: true })
			.then((result) => {
				const placeIndex = result.rows.findIndex((row) => {
					return !row.id.includes("_design") && row.doc?.site_id === doc?.place?.site_id
				})
				if (placeIndex > -1) {
					setPlace(result.rows[placeIndex].doc)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.places, doc])

	// Update changes to database
	useEffect(() => {
		if (isEdited) {
			databases.locals.places
				.put(place)
				.then((result) => {
					console.log(result)
					console.log(`${t("toast.place")} "${place.name}" ${t("toast.updated")}`)
					toast(`${t("toast.place")} "${place.name}" ${t("toast.updated")}`)
					setIsEdited(false)
					setIsUpdated(true)
				})
				.catch((err) => {
					console.log(`${t("toast.error_updating_place")} "${place.name}":`, err)
					toast(`${t("toast.error_updating_place")} "${place.name}"`)
				})
		}
	}, [isEdited, place, databases.locals.places, match, t, toast])

	return (
		<IonPage>
			<Header title={t("places.tasks")} backButton />
			<IonContent>
				{doc._id && place._id ? (
					<>
						<IonList>
							<div className="ion-border-bottom ion-padding-left ion-padding-right">
								<div className="ticket-inner-wrap">
									<IonText>
										<h4>{t("places.tasks")}</h4>
									</IonText>
									<IonText>
										<p>{t("places.tasks_sub_title")}</p>
									</IonText>
								</div>
							</div>
						</IonList>
						<Tasks doc={place} setDoc={setPlace} setIsEdited={setIsEdited} showNotCompleted />
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default TicketTasks
